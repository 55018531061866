import { DatabaseMetadata, Patient } from '.'

/**
 * Note: this is NOT the complete type definition for CalendarHoldModel.
 * It is only a placeholder so that we can use it for the collection mapping.
 * The full type definition is in Luna. Please refer to it there when building
 * any new functionality that uses this type.
 */
export type CalendarHoldModel = {
  patientId: Patient['oid']
}

export type CalendarHold = CalendarHoldModel & DatabaseMetadata
