import { AccountSettings } from '../account'
import { MilestoneContentType } from '../achievements'
import { AcuityAppointment } from '../acuity'
import { AppointmentTypeString } from '../appointments'
import {
  CreatePaymentCard,
  PaymentDownload,
  PaymentInvoice,
  PaymentPaymentSource,
  UpdatePaymentCard,
} from '../chargebee'
import { FinancialConsentVersion, TpoConsentVersion } from '../consents'
import { ISOString } from '../dates'
import { DoseSpotPharmacy } from '../doseSpot'
import {
  AggregateInvoiceInfo,
  FormattedStripeSubscription,
  InvoiceListItem,
  PaymentPlanFrequency,
} from '../invoices'
import {
  Achievement,
  Consent,
  Patient,
  PatientReleaseOfInformation,
  PatientReleaseOfInformationModel,
  Referral,
} from '../models'
import { ReferralPartner } from '../partners'
import { Consents, CurrentUserPatient, PatientProvidedInsuranceData } from '../patient'
import { ReferralEventContext, ReferralEventTrigger, ReferralSource } from '../referrals'
import { SegmentContextProperties, SegmentEventName } from '../segment'
import {
  PatientFormTaskType,
  PatientPortalTaskCard,
  PatientScheduleTaskType,
  PatientTaskType,
  PatientWorkflowTaskType,
} from '../tasks'
import { TaskFormStepType, TaskPayload } from '../tasks/taskFields'
import { ThankYouNote } from '../thankYouNote'
import { DeepPartial } from '../utils'
import {
  Prompt,
  PromptResponseModel,
  PromptResponsePayload,
  WellnessCheckWorkflowType,
  WorkflowSession,
  WorkflowSessionMetadata,
  WorkflowSessionStatus,
  WorkflowType,
} from '../workflows'
import { OpheliaRoute } from './utils'

export type AppointmentInfo = {
  id: number
  calendar: string
  calendarId: number
  status: AppointmentStatus
  datetime: string
  confirmed: boolean
  canceled: boolean
  visitType: AppointmentTypeString
  visitTypeId: number
  duration: string
  location: string
  canReschedule: boolean
}

export const APPOINTMENT_STATUSES = [
  'canceled',
  'late-cancellation',
  'no-show',
  'attended',
  'happening-now',
  'happening-soon',
  'none',
] as const

export type AppointmentStatus = (typeof APPOINTMENT_STATUSES)[number]

export type PrescriptionInfo = {
  prescriptionId: number
  genericDrugName: string | null
  displayStatus: string
  pharmacyId: number
  writtenDate: string
  strength: string | null
  daysSupply: number | undefined
}

export type WorkflowProgress = {
  current: number
  total: number
}

type PromptResponseData = Omit<
  PromptResponseModel,
  'patientId' | 'promptId' | 'promptContextKey' | 'sessionId' | 'workflowId'
>

namespace WorklowState {
  type NotStarted = {
    status: 'not_started'
    prompt: Prompt
    progress: WorkflowProgress
  }

  type InProgress = {
    status: 'in_progress'
    prompt: Prompt
    response: PromptResponseData | undefined
    progress: WorkflowProgress
  }

  type Complete = {
    status: 'complete'
    redirect?: string | undefined
  }

  export type Status<T extends WorkflowSessionStatus = WorkflowSessionStatus> =
    T extends 'not_started'
      ? NotStarted
      : T extends 'in_progress'
      ? InProgress
      : T extends 'complete'
      ? Complete
      : never
}

export type GetWorkflowPromptResponse<T extends WorkflowSessionStatus = WorkflowSessionStatus> = {
  sessionId: string
  workflowId: string
  metadata: WorkflowSessionMetadata | undefined
} & WorklowState.Status<T>

export const TOKEN_EXPIRED_ERROR_MESSAGE = 'Token expired'

export type GetNextWorkflowStateRequest<T extends 'type' | 'session' = 'type' | 'session'> =
  T extends 'type'
    ? { params: { by: T; value: WorkflowType }; body: GetNextWorkflowStateRequest.ByTypeBody }
    : { params: { by: T; value: string }; body: GetNextWorkflowStateRequest.BySessionBody }

export function isGetNextWorkflowStateRequest<T extends 'type' | 'session'>(
  request: GetNextWorkflowStateRequest,
  by: T,
): request is GetNextWorkflowStateRequest<T> {
  return request.params.by === by
}

export type WorkflowVariant = 'referral-partner'

export namespace GetNextWorkflowStateRequest {
  export type ByTypeBody = {
    /** Should the workflow be resumed if not completed. */
    resume?: boolean
    variant: WorkflowVariant | undefined
    metadata: WorkflowSessionMetadata | undefined
  }

  export type BySessionBody = {
    /** Response for the last prompt. */
    promptId?: string
    /** Prompt response. */
    response?: PromptResponsePayload
    /** Optional token ID for public wellness checks scoped to a specific patient. */
    token?: string | undefined
  }

  export type Body<T extends 'type' | 'session' = never> = T extends 'type'
    ? ByTypeBody
    : T extends 'session'
    ? BySessionBody
    : never
}

export type GetNextWorkflowStateResponse<T extends WorkflowSessionStatus = WorkflowSessionStatus> =
  {
    sessionId: string
    workflowId: string
  } & WorklowState.Status<T>

export type GetFileReadRequestParams = {
  id: string
}

export type DeleteFileRequestParams = {
  id: string
}

export type GetFileReadResponse = {
  signedUrl: string
}

export type GetFileWriteResponse = {
  fileId: string
  signedUrl: string
}

export type GetPatientFilesQueryParams = {
  latest: string
  category: 'ID' | 'insurance'
}

export type UpdateMeRequestBody = DeepPartial<
  Omit<CurrentUserPatient, 'account'> & {
    account: {
      timezone: string
      settings: AccountSettings
      zoomLastTested: string
      isZoomWorking: boolean
    }
  }
>

export type GetPatientTaskResponse<T extends PatientTaskType = PatientTaskType> = {
  type: T | 'redirect'
} & (T extends PatientWorkflowTaskType
  ? {}
  : T extends PatientFormTaskType
  ? { data: TaskPayload<T> }
  : T extends PatientScheduleTaskType
  ? {
      visitTypeId: number
      calendarId?: number
      slots: { date: string; calendarIDs: number[] }[]
      startDatetime?: string
    }
  : void)

export type PutPatientTaskRequest<T extends PatientTaskType = PatientTaskType> = {
  params: { type: T; step: TaskFormStepType<T> }
  data: T extends PatientFormTaskType
    ? TaskPayload<T>
    : T extends PatientScheduleTaskType
    ? { datetime: string; calendarID: number | undefined }
    : void
}

export type PutPatientTaskResponse<T extends PatientTaskType = PatientTaskType> = {
  allRequiredTasksCompleted?: boolean
  isTaskComplete: boolean
  payload: T extends PatientScheduleTaskType ? AcuityAppointment : void
}

export type GetPatientInsuranceDataResponse = {
  status: 'in-review' | 'rejected' | 'approved' | 'none'
  hasInsurance: boolean
  data?: PatientProvidedInsuranceData
}

export type CreateCardPaymentSourceRequestBody = {
  card: CreatePaymentCard
  makePrimaryCard?: boolean
}

export type UpdatePaymentSourceRequestBody = {
  card: UpdatePaymentCard
  cardId: string
}

export type GetCollectNowRequestParams = {
  redirect_url: string
}

export type SetEmailAndConsentsRequestBody = {
  email: string
  emailOnFail: boolean
  consents: Consents
}

export type SetEmailAndConsentsResponse = { status: 'confirmation-sent' | 'successful' }

export type GetPatientTasksSummaryData = GetPatientTaskStatusSummaryResponse

export type ReferralEvent = {
  datetime: string
  anonymousId: string
  triggeredBy: ReferralEventTrigger
  context: ReferralEventContext
}

export type PasswordType = 'pin' | 'password'

export type AppointmentParamType = 'consultation' | 'initial-visit'

export type AuthenticationMethod =
  | 'accountRecovery'
  | 'impersonation'
  | 'passwordReset'
  | 'phone'
  | 'script'
  | PasswordType
  | undefined

export type PatientPaymentInvoiceResponse = {
  invoices: PaymentInvoice[]
}

export type ListPaymentPaymentSourceResponse = {
  list: PaymentPaymentSource[]
}

// Stripped down version of Stripe.PaymentMethod
export type StripePaymentMethod = {
  id: string
  brand: string
  isDefault: boolean
  last4: string
  expMonth: number
  expYear: number
}

export type PaymentHostedPageResponse = {
  hosted_page: {
    id?: string
    type?: string
    url?: string
    state?: string
    pass_thru_content?: string
    created_at?: number
    embed?: boolean
    expires_at?: number
    object?: string
    resource_version?: number
    updated_at?: number
    content?: JSON
    checkout_info?: JSON
  }
}

export type PaymentPDFDownloadResponse = {
  download: PaymentDownload
}

export type CreatePatientReleaseOfInformationData = PatientReleaseOfInformationModel
export type GetAllPatientReleasesOfInformationData = PatientReleaseOfInformation

export type GetPatientTaskStatusSummaryResponse = {
  tasks: {
    complete: boolean
    required: boolean
    type: PatientTaskType
    title: string
  }[]
}

export type DashboardAlertType = 'thank-you-note'
export type DashboardAlert<T extends DashboardAlertType> = {
  type: T
  data: T extends 'thank-you-note' ? ThankYouNote : null
}

export const PATIENT_URL_SEARCH_PARAM = {
  StandbyDatetime: {
    key: 'stby',
  },
  FlexibleAppointment: {
    key: 'flex',
    value: 'true',
  },
}

export type PatientApi = OpheliaRoute<
  'GET /appointments',
  {
    req: {
      query: {
        start?: ISOString
        end?: ISOString
        canceled?: 'yes' | 'no'
        confirmed?: 'yes' | 'no'
        type?: AppointmentTypeString
        dateOrder?: 'asc' | 'desc'
      }
    }
    res: AppointmentInfo[]
  }
> &
  OpheliaRoute<
    'GET /appointments/:id',
    {
      req: { params: { id: string } }
      res: AppointmentInfo
    }
  > &
  OpheliaRoute<
    'PUT /appointments/:id/confirm',
    {
      req: { params: { id: string } }
      res: Response
    }
  > &
  OpheliaRoute<
    'PUT /appointments/:id/cancel',
    {
      req: { params: { id: string } }
      res: Response
    }
  > &
  OpheliaRoute<
    'GET /appointments/:id/confirm',
    {
      req: { params: { id: string } }
      res: {
        id: number
        calendar: string
        status: AppointmentStatus
        datetime: string
        confirmed: boolean
        visitType: AppointmentTypeString
        duration: string
        patientId: Patient['oid']
      }
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/free-consultation-call',
    {
      req: never
      res: { date: string; calendarIDs: number[] }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/free-consultation-call/:date',
    {
      req: {
        params: { date: string }
        query: {
          visitTypeId?: string
          calendarId?: string
          startDatetime?: string | undefined
        }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/returning-welcome-call',
    {
      req: never
      res: { date: string; calendarIDs: number[] }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/returning-welcome-call/:date',
    {
      req: {
        params: { date: string }
        query: {
          visitTypeId?: string
          calendarId?: string
          startDatetime?: string | undefined
        }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/:type/:date',
    {
      req: {
        params: { date: string; type: AppointmentTypeString }
        query: {
          visitTypeId?: string
          calendarId?: string
          startDatetime?: string | undefined
        }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'PUT /appointments/:id/reschedule',
    {
      req: {
        params: { id: string }
        data: { datetime: string; calendarID?: number | undefined }
      }
      res: AcuityAppointment
    }
  > &
  OpheliaRoute<
    'GET /appointments/:id/reschedule/calendar',
    {
      req: {
        params: { id: string }
        query: { showPreferences: 'true' | 'false' }
      }
      res: { date: string; calendarIDs: number[] }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/:id/reschedule/calendar/:date',
    {
      req: {
        params: { id: string; date: string }
        query: { showPreferences: 'true' | 'false' }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/schedule/consultation/queue',
    {
      req: never
      res: { isAvailable: boolean }
    }
  > &
  OpheliaRoute<
    'POST /appointments/consultation',
    {
      req: {
        data: {
          firstName: string
          timezone: string
          workflowSessionId: WorkflowSession['oid']
          addressState: string
        }
      }
      res: { appointmentId: string }
    }
  > &
  OpheliaRoute<
    'POST /appointments/reenrollment',
    {
      req: {
        data: {
          firstName: string
          timezone: string
          workflowSessionId: WorkflowSession['oid']
          addressState: string
        }
      }
      res: { appointmentId: string }
    }
  > &
  OpheliaRoute<
    'GET /consultation/queue/status',
    {
      req: {
        query: {
          anonymousId: string
        }
      }
      res: { status: 'open' | 'closed' | 'closing soon' | 'full' }
    }
  > &
  OpheliaRoute<
    'POST /appointments/schedule/consultation/queue',
    {
      req: never
      res: void
    }
  > &
  OpheliaRoute<
    'POST /account/recovery',
    {
      req: {
        data: {
          email: string
        }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'GET /pharmacy/find',
    {
      req: {
        query: {
          pharmacyId: string
        }
      }
      res: DoseSpotPharmacy
    }
  > &
  OpheliaRoute<
    'POST /forms/waitlist',
    {
      req: {
        data: {
          state: string
          phone: string
        }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'PUT /account/recovery',
    {
      req: never
      res: Response
    }
  > &
  OpheliaRoute<
    'POST /pay-invoices',
    {
      req: {
        data: {
          invoiceIds: InvoiceListItem['oid'][]
          paymentMethodId: string
        }
      }
      res: never
    }
  > &
  OpheliaRoute<
    'POST /release-of-information',
    { req: { data: PatientReleaseOfInformationModel }; res: { id: string } }
  > &
  OpheliaRoute<
    'GET /prescriptions',
    {
      req: {
        query: {
          type: 'historical' | 'current'
        }
      }
      res: PrescriptionInfo[]
    }
  > &
  OpheliaRoute<
    'GET /tasks',
    {
      req: never
      res: { tasks: PatientPortalTaskCard[] }
    }
  > &
  OpheliaRoute<
    'GET /task/:type',
    {
      req: { params: { type: PatientTaskType } }
      res: GetPatientTaskResponse
    }
  > &
  OpheliaRoute<
    'PUT /task/:type/:step',
    {
      req: PutPatientTaskRequest
      res: PutPatientTaskResponse
    }
  > &
  OpheliaRoute<
    'GET /payment/pdf',
    {
      req: {
        query: {
          invoiceId: string
        }
      }
      res: {
        download_url?: string
      }
    }
  > &
  OpheliaRoute<
    'GET /experiments',
    {
      req: never
      res: {
        experimentTreatmentGroups?: string[]
        experimentControlGroups?: string[]
      }
    }
  > &
  OpheliaRoute<'GET /consents', { req: never; res: Consent[] }> &
  OpheliaRoute<
    'GET /consents/:type/:version',
    { req: { params: { type: 'financial' | 'treatment' | 'tpo'; version: string } }; res: Consent }
  > &
  OpheliaRoute<
    'POST /consents/financial',
    {
      req: {
        data: {
          signature: string
          version: FinancialConsentVersion
          token: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /consents/tpo',
    {
      req: {
        data: {
          signature: string
          version: TpoConsentVersion
          token: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /appointments/consultation',
    {
      req: never
      res: { datetime: string; callMeNow: boolean } | void
    }
  > &
  OpheliaRoute<
    'GET /appointments/reenrollment',
    {
      req: never
      res: { datetime: string; callMeNow: boolean } | void
    }
  > &
  OpheliaRoute<
    'GET /referral/appointments/consultation',
    {
      req: { query: { workflowSessionId: string } }
      res: { datetime: string; callMeNow: boolean } | void
    }
  > &
  OpheliaRoute<
    'GET /referral/appointments/reenrollment',
    {
      req: { query: { workflowSessionId: string } }
      res: { datetime: string; callMeNow: boolean } | void
    }
  > &
  OpheliaRoute<
    'GET /wellness-assessment-url',
    {
      req: {
        query: {
          token: string
        }
      }
      res: {
        combinedWellnessAssessmentUrl: string | null
        wellnessAssessments: WellnessCheckWorkflowType[]
      }
    }
  > &
  OpheliaRoute<
    'GET /meeting/:meetingId',
    {
      req: {
        params: {
          meetingId: string
        }
        query: {
          password: string
          standbyDatetime: string
        }
      }
      res: {
        zoomUrl: string | null
        wellnessCheckUrl?: string
        financialConsentToken: string | null
        tpoConsentToken: string | null
        standbyException: {
          message: string
          originalStartTime: string
        } | null
      }
    }
  > &
  OpheliaRoute<
    'POST /payment/setup-intent',
    {
      req: Record<string, never>
      res: {
        clientSecret: string
      }
    }
  > &
  OpheliaRoute<
    'POST /payment/intent',
    {
      req: {
        data: {
          amountInCents: number
          stripeInvoiceIds: string[]
          createdBy: 'stripe_payment_request_button'
        }
      }
      res: {
        paymentIntentId: string
        clientSecret: string | null
      }
    }
  > &
  OpheliaRoute<
    'POST /invoices/pdf',
    {
      req: {
        data: { invoiceId: string }
      }
      res: { fileName: string; signedUrl: string }
    }
  > &
  OpheliaRoute<
    'GET /invoices',
    {
      req: never
      res: { invoiceListItems: InvoiceListItem[]; aggregateInvoiceInfo: AggregateInvoiceInfo }
    }
  > &
  OpheliaRoute<
    'GET /payment/methods',
    {
      req: never
      res: { paymentMethods: StripePaymentMethod[] } & (
        | { isPaymentMethodOptional: false }
        | { isPaymentMethodOptional: true; paymentMethodOptionalMessage: string }
      )
    }
  > &
  OpheliaRoute<
    'DELETE /payment/methods/:paymentMethodId',
    { req: { params: { paymentMethodId: string } }; res: void }
  > &
  OpheliaRoute<
    'POST /payment/methods/default',
    { req: { data: { paymentMethodId: string } }; res: void }
  > &
  OpheliaRoute<
    'GET /payment/methods/:paymentMethodId',
    {
      req: {
        params: { paymentMethodId: string }
      }
      res: StripePaymentMethod
    }
  > &
  OpheliaRoute<
    'PUT /payment/methods',
    {
      req: {
        data: { newPaymentMethodId: string; oldPaymentMethodId: string; setAsDefault: boolean }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /subscription',
    {
      req: never
      res: FormattedStripeSubscription
    }
  > &
  OpheliaRoute<
    'GET /configs',
    {
      req: never
      res: {
        outageBanner?:
          | {
              message: string
              enabled: boolean
            }
          | undefined
      }
    }
  > &
  OpheliaRoute<
    'POST /payment-plan',
    {
      req: {
        data: {
          paymentPlanFrequency: PaymentPlanFrequency
          numPayments: number
          balancePerPaymentInDollars: number
        }
      }
      res: void
    }
  > &
  OpheliaRoute<'GET /payment-plan', { req: never; res: boolean }> &
  OpheliaRoute<
    'POST /workflow/:by/:value',
    { req: GetNextWorkflowStateRequest; res: GetNextWorkflowStateResponse }
  > &
  OpheliaRoute<
    'POST /referral/workflow/:by/:value',
    { req: GetNextWorkflowStateRequest; res: GetNextWorkflowStateResponse }
  > &
  OpheliaRoute<
    'POST /not-ready/workflow/:by/:value',
    { req: GetNextWorkflowStateRequest; res: GetNextWorkflowStateResponse }
  > &
  OpheliaRoute<
    'POST /wellness-check/workflow/:by/:value',
    { req: GetNextWorkflowStateRequest; res: GetNextWorkflowStateResponse }
  > &
  OpheliaRoute<
    'GET /workflow/session/:sessionId/prompt/:promptId',
    { req: { params: { sessionId: string; promptId: string } }; res: GetWorkflowPromptResponse }
  > &
  OpheliaRoute<
    'GET /referral/workflow/session/:sessionId/prompt/:promptId',
    { req: { params: { sessionId: string; promptId: string } }; res: GetWorkflowPromptResponse }
  > &
  OpheliaRoute<
    'GET /not-ready/workflow/session/:sessionId/prompt/:promptId',
    { req: { params: { sessionId: string; promptId: string } }; res: GetWorkflowPromptResponse }
  > &
  OpheliaRoute<
    'GET /wellness-check/workflow/session/:sessionId/prompt/:promptId',
    {
      req: { params: { sessionId: string; promptId: string }; query: { token: string } }
      res: GetWorkflowPromptResponse
    }
  > &
  OpheliaRoute<
    'GET /referralPartners/:referralPartnerId',
    {
      req: {
        params: {
          referralPartnerId: string
        }
      }
      res: ReferralPartner
    }
  > &
  OpheliaRoute<
    'GET /referral/friend-or-family/:referralId/personalization',
    {
      req: { params: { referralId: string } }
      res: { personalizedReferral: Referral['personalizedReferral'] | null }
    }
  > &
  OpheliaRoute<
    'POST /referral/friend-or-family',
    {
      req: {
        data: {
          referrerPatientId: Patient['oid'] | undefined
          anonymousId: string
          source: ReferralSource
          personalizedReferral?:
            | { showReferrerMessage: false }
            | {
                showReferrerMessage: true
                referrerName: string
                referrerMessage: string
              }
        }
      }
      res: {
        referralLink: string
        referralId: string
      }
    }
  > &
  OpheliaRoute<
    'GET /achievements',
    {
      req: never
      res: Achievement[]
    }
  > &
  OpheliaRoute<
    'POST /milestone/link',
    {
      req: {
        data: {
          sharedByPatientId: Patient['oid']
          milestoneName: MilestoneContentType['value']
        }
      }
      res: {
        linkUrl: string
      }
    }
  > &
  OpheliaRoute<
    'PUT /achievements/:achievementId/seen',
    {
      req: {
        params: { achievementId: Achievement['oid'] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /chatbox/status',
    {
      req: never
      res: boolean
    }
  > &
  OpheliaRoute<
    'GET /dashboard-alerts',
    {
      req: never
      res: { type: DashboardAlertType; data: ThankYouNote }[]
    }
  > &
  OpheliaRoute<
    'POST /dashboard-alerts/dismiss/:type/:id',
    {
      req: { params: { type: DashboardAlertType; id: string } }
      res: never
    }
  > &
  OpheliaRoute<
    'POST /segment-events',
    {
      req: { data: { event: SegmentEventName; properties?: SegmentContextProperties } }
      res: never
    }
  >
