/* eslint-disable no-magic-numbers */
import merge from 'lodash.merge'
import {
  AddressData,
  Appointment,
  CandidEncounter,
  CandidEncounterV3,
  COCMRegistryItem,
  COCMRegistryItemModel,
  DecoratedProblem,
  EligibleInsuranceData,
  Employee,
  Encounter,
  EncounterStatuses,
  InsuranceReviewData,
  ISOString,
  ManualInsuranceData,
  MMMMDoYYYYhmma,
  ObjectiveMeasureResponse,
  Patient,
  PatientModel,
  PatientReleaseOfInformation,
  PersonalData,
  Phq8FormResponse,
  ProblemListProblem,
  VisitNote,
} from '.'
import { ActionItemAssignmentMethod, ActionItemStatus, ActionItemType } from './actionItems'
import { InsuranceData, InsuranceQueueStatus } from './api'
import {
  DoseSpotPharmaciesResponse,
  DoseSpotPharmacy,
  DoseSpotPrescription,
  DoseSpotResultResponse,
} from './doseSpot'
import { ActionItemModel, PrescriptionFavorite, PrescriptionFavoriteModel } from './models'
import { Prompt, PromptResponse, Workflow, WorkflowPromptV2, WorkflowSession } from './workflows'
export const userId = '123'
export const employeeId = '789'
export const id = '2'
export const appointmentId = 5

export const baseRoiData = {
  recipientType: 'Person',
  recipient: 'Kermit',
  fax: '+11111111111',
  canContactPhone: true,
  signature: 'Joanna Piggy',
} as PatientReleaseOfInformation

export const addMinutesFromDate = (objDate: Date, minutes: number) => {
  const numberOfMlSeconds = objDate.getTime()
  const addMlSeconds = minutes * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)
  return newDateObj
}

const substractDaysFromDate = (objDate: Date, days: number) => {
  const numberOfMlSeconds = objDate.getTime()
  const subtractMlSeconds = days * 24 * 60 * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds - subtractMlSeconds)
  return newDateObj
}

export const addDaysToDate = (objDate: Date, days: number) => {
  const numberOfMlSeconds = objDate.getTime()
  const addMlSeconds = days * 24 * 60 * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)
  return newDateObj
}

// 2017-02-14T12:51:48.000Z
export const testDatetime = 1487076708000

/**
 * unix date to be used with 'stopping time' for tests. Example:
 * Date.now = jest.fn(() => 1487076708000)
 */

const now = new Date(testDatetime)
export const twoDaysAgo = substractDaysFromDate(now, 2).toISOString() as ISOString
export const twoMonthsAgo = substractDaysFromDate(now, 61).toISOString() as ISOString
export const twoDaysLater = addDaysToDate(now, 2).toISOString() as ISOString

export const address1 = {
  address1: 'address1',
  city: 'city',
  state: 'NY',
  zip_code: '12345',
  zip_plus_four_code: '1234',
}

export const shippingAddress1: AddressData = {
  address: 'address1',
  city: 'city',
  state: 'NY',
  zip: '12345',
}

export const taskShippingAddress = {
  address: shippingAddress1.address ?? '',
  addressTwo: shippingAddress1.aptSuite ?? '',
  city: shippingAddress1.city ?? '',
  state: shippingAddress1.state ?? '',
  zipCode: shippingAddress1.zip ?? '',
}

export const getCandidEncounter1 = (data?: Partial<CandidEncounter>): CandidEncounter =>
  merge(
    {
      patient_responsibility_cents: '5000',
      allowed_amount_cents: '',
      appointment_type: '',
      billing_provider_first_name: '',
      billing_provider_last_name: '',
      billing_provider_npi: '',
      billing_provider_organization_name: '',
      billing_provider_tax_id: '',
      charge_amount_cents: '',
      check_dates: '',
      created_datetime: '',
      date_of_service: '',
      diagnosis_codes: '',
      do_not_bill: '',
      encounter_external_id: id,
      encounter_id: '',
      paid_amount_cents: '',
      patient_external_id: userId,
      payer_cpid: '',
      payer_id: '',
      payer_name: '',
      place_of_service_code: '',
      procedure_codes: '',
      rendering_provider_first_name: '',
      rendering_provider_last_name: '',
      rendering_provider_npi: '',
      rendering_provider_organization_name: '',
      service_facility_city: '',
      service_facility_organization_name: '',
      service_facility_state: '',
      service_facility_zip_code: '',
      state_transition_datetime: '2022-01-12 16:21:06.625192',
      status: '',
    },
    data,
  )

export const getCandidEncounterV3 = (data?: Partial<CandidEncounterV3>): CandidEncounterV3 =>
  merge(
    {
      patient_balance_cents: '5000',
      patient_responsibility_cents: '5000',
      allowed_amount_cents: '',
      appointment_type: '',
      billing_provider_first_name: '',
      billing_provider_last_name: '',
      billing_provider_npi: '',
      billing_provider_organization_name: '',
      billing_provider_tax_id: '',
      charge_amount_cents: '',
      check_dates: '',
      created_datetime: '',
      date_of_service: '',
      diagnosis_codes: '',
      do_not_bill: '',
      encounter_external_id: id,
      encounter_id: '',
      paid_amount_cents: '',
      patient_external_id: userId,
      payer_cpid: '',
      payer_id: '',
      payer_name: '',
      place_of_service_code: '',
      procedure_codes: '',
      rendering_provider_first_name: '',
      rendering_provider_last_name: '',
      rendering_provider_npi: '',
      rendering_provider_organization_name: '',
      service_facility_city: '',
      service_facility_organization_name: '',
      service_facility_state: '',
      service_facility_zip_code: '',
      state_transition_datetime: '2022-01-12 16:21:06.625192',
      status: '',
    },
    data,
  ) as CandidEncounterV3

const baseEncounter: Readonly<Encounter> = {
  oid: id,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  onAllowList: true,
  appointmentId: 12345,
  status: 'unsubmitted' as EncounterStatuses,
  date_of_service: '2022-03-12',
  patient_authorized_release: true,
  benefits_assigned_to_provider: true,
  provider_accepts_assignment: true,
  appointment_type: 'OUD Maintenance Visit',
  place_of_service_code: '02',
  billing_provider: { npi: 'npi', tax_id: 'tax_id', address: address1 },
  rendering_provider: {
    npi: 123,
    first_name: 'first_name',
    last_name: 'last_name',
    address: address1,
  },
  service_facility: { organization_name: 'name', address: address1 },
  patient: {
    gender: 'female',
    first_name: 'first_name',
    last_name: 'last_name',
    date_of_birth: '2000-01-01',
    external_id: userId,
    address: address1,
  },
  subscriber_primary: {
    gender: 'female',
    first_name: 'first_name',
    last_name: 'last_name',
    patient_relationship_to_subscriber_code: '20',
    date_of_birth: '2000-01-01',
    address: {
      address1: 'address1',
      city: 'city',
      state: 'NY',
      zip_code: '22222',
    },
    insurance_card: {
      member_id: 'x',
      payer_name: 'x',
      payer_id: 'x',
      group_number: 'x',
      plan_name: 'x',
      plan_type: 'MC',
      rx_bin: 'x',
      rx_pcn: 'x',
      image_url_front: 'x',
      image_url_back: 'x',
    },
  },
  diagnoses: [],
  service_lines: [],
}

export const getEncounter1 = (data?: Partial<Encounter>): Encounter =>
  merge({ ...baseEncounter }, data)

export const getEncounterWithMedicaidInfo = (data?: Partial<Encounter>): Encounter =>
  merge(
    {
      ...baseEncounter,
      subscriber_primary: {
        ...baseEncounter.subscriber_primary,
        insurance_card: {
          ...baseEncounter.subscriber_primary?.insurance_card,
          useMedicaidInfo: true,
          medicaidInfo: {
            member_id: '12345',
            payer_name: 'Payer',
            payer_id: '123',
          },
        },
      },
    },
    data,
  )
export const getEncounterWithSecondaryInsurance = (data?: Partial<Encounter>): Encounter =>
  merge(
    {
      ...baseEncounter,
      subscriber_secondary: {
        gender: 'female',
        first_name: 'first_name',
        last_name: 'last_name',
        patient_relationship_to_subscriber_code: '43',
        date_of_birth: '2000-01-01',
        address: {
          address1: 'TEST',
          city: 'Test',
          state: 'NY',
          zip_code: '22222',
        },
        insurance_card: {
          member_id: 'x',
          payer_name: 'x',
          payer_id: 'x',
          group_number: 'x',
          plan_name: 'x',
          plan_type: 'MC',
          rx_bin: 'x',
          rx_pcn: 'x',
          image_url_front: 'x',
          image_url_back: 'x',
        },
      },
    },
    data,
  )

const testStartTimeMinutesFromNow = 58
const testEndTimeMinutesFromNow = 20
const baseAppointment: Appointment = Object.freeze({
  oid: 'test',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  id: appointmentId,
  appointmentTypeID: 3,
  calendar: 'xxx',
  calendarID: 8,
  calendarTimezone: 'EST',
  canceled: false,
  category: 'foo',
  confirmed: false,
  confirmationPage: 'bar',
  date: now.toISOString(),
  dateCreated: now.toISOString(),
  datetime: addMinutesFromDate(now, testStartTimeMinutesFromNow).toISOString() as ISOString,
  datetimeCreated: now.toISOString(),
  duration: '20',
  email: 'foo@bar.com',
  employeeId: 'abc',
  endTime: addMinutesFromDate(now, testEndTimeMinutesFromNow).toISOString(),
  firstName: 'John',
  lastName: 'Doe',
  location: 'Zoom',
  phone: '+11234567890',
  time: now.toISOString(),
  timezone: 'EST',
  type: 'Initial Visit',
})

const baseUnconfirmedAppointment: Omit<Appointment, 'confirmed'> = Object.freeze({
  oid: 'test',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  id: appointmentId,
  appointmentTypeID: 3,
  calendar: 'xxx',
  calendarID: 8,
  calendarTimezone: 'EST',
  canceled: false,
  category: 'foo',
  confirmationPage: 'bar',
  date: now.toISOString(),
  dateCreated: now.toISOString(),
  datetime: addMinutesFromDate(now, testStartTimeMinutesFromNow).toISOString() as ISOString,
  datetimeCreated: now.toISOString(),
  duration: '20',
  email: 'foo@bar.com',
  employeeId: 'abc',
  endTime: addMinutesFromDate(now, testEndTimeMinutesFromNow).toISOString(),
  firstName: 'John',
  lastName: 'Doe',
  location: 'Zoom',
  phone: '+11234567890',
  time: now.toISOString(),
  timezone: 'EST',
  type: 'Initial Visit',
})

export const getAppointment1 = (data?: Partial<Appointment>): Appointment => {
  return { ...baseAppointment, ...data }
}

export const getUnconfirmedAppointment1 = (
  data?: Partial<Appointment>,
): Omit<Appointment, 'confirmed'> => {
  return { ...baseUnconfirmedAppointment, ...data }
}

export const appointment2: Appointment = {
  ...baseAppointment,
  calendarID: 12,
  calendarTimezone: 'PST',
  datetime: twoDaysAgo,
}

export const appointment3: Appointment = {
  ...baseAppointment,
  calendarID: 12,
  calendarTimezone: 'PST',
  datetime: twoDaysLater,
}

export const noShowAppointment: Appointment = {
  ...baseAppointment,
  noShow: true,
  canceled: true,
}

export const cancelledAppointment: Appointment = {
  ...baseAppointment,
  noShow: false,
  canceled: true,
}

export const visitNoteAddendum1 = {
  userId,
  visitId: appointmentId,
  type: 'maintenance',
  content: 'Addendum comment',
  clinicianCalendarId: 0,
  clinicianName: 'Leora',
  clinicianUserId: '123',
  timestamp: '12-12-2022' as MMMMDoYYYYhmma,
}

export const visitNote1: VisitNote = {
  oid: id,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  content: { mockContent: 'hello' },
  createdAt: '12-12-2022',
  type: 'maintenance',
  visitId: appointmentId,
  updatedAt: '12-22-2022',
}

export const personalData1: PersonalData = {
  firstName: 'Foo',
  lastName: 'McBar',
  preferredName: 'Bäz',
  email: 'foo@bar.com',
  birthday: '1/1/1970',
  phone: '+11234567890',
  sex: 'male',
  pronouns: 'he/him/his',
}

export const personalData2: PersonalData = {
  firstName: 'John',
  lastName: 'McClane',
  preferredName: 'Johnny',
  email: 'john@bar.com',
  birthday: '1/1/1980',
  phone: '+19876543210',
  sex: 'male',
  pronouns: 'he/him/his',
}

export const employeeData1: Employee = {
  oid: 'asdf1234',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  email: 'foo@bar.com',
  calendarId: 1,
  role: 'employee',
  name: 'foo bar',
  legalFirstName: 'foo',
  legalLastName: 'bar',
  profilePhotoURL: 'https://cdn.com/foo.jpg',
  dob: '09-08-1996',
  status: 'currentEmployee',
  employmentStatus: 'employee',
  personalEmail: '',
  phone: '',
  startDate: '',
}

export const employeeData2: Employee = {
  oid: 'hjkl4567',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  email: 'baz@bar.com',
  calendarId: 2,
  role: 'employee',
  name: 'baz bar',
  legalFirstName: 'baz',
  legalLastName: 'bar',
  profilePhotoURL: 'https://cdn.com/foo.jpg',
  dob: '09-08-1996',
  status: 'currentEmployee',
  employmentStatus: 'employee',
  personalEmail: '',
  phone: '',
  startDate: '',
}

export const queueItem1: InsuranceReviewData = {
  status: InsuranceQueueStatus.New,
  timestamp: twoDaysAgo,
}

export const queueItem2: InsuranceReviewData = {
  timestamp: now.toISOString(),
  status: InsuranceQueueStatus.New,
}

export const patient1 = Object.freeze({
  personalData: personalData1,
  doseSpotId: 2,
  statuses: {
    patient: 'lead',
  },
}) as Patient

export const patientX: Patient = {
  oid: userId,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  account: {
    createdAt: '2022-04-11T20:44:40.345Z',
    settings: {
      notifications: {
        standbyList: {
          initialVisit: {
            optIn: true,
            availabilities: [{ time: 'morning', weekday: 'monday' }],
            previousAvailableSlots: [{ time: '2022-04-11T10:00:00.000Z', slotsAvailable: 1 }],
          },
          welcomeCall: {
            optIn: true,
            availabilities: null,
          },
        },
      },
    },
  },
  personalData: personalData2,
  statuses: {
    patient: 'lead',
  },
  insuranceData: {
    insuranceQueueData: queueItem1,
  },
}

export const patientXTask: Patient = {
  oid: userId,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  homeData: {
    address: 'address1',
    city: 'city',
    state: 'Pennsylvania',
    zip: 'zip',
  },
  personalData: personalData1,
  statuses: {
    patient: 'lead',
  },
  insuranceData: {
    insuranceQueueData: queueItem1,
    hasInsurance: true,
  },
}

export const reconnectingCompletePatient: Patient = {
  oid: userId,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  userId,
  personalData: personalData1,
  statuses: {
    patient: 'candidate',
  },
  insuranceData: {
    insuranceQueueData: queueItem1,
  },
  workflows: {
    medical_intake_form: {
      status: 'complete',
      sessionId: 'reconnectSession',
      workflowId: 'reconnectWorkflow',
    },
  },
}

export const validWorkflowSession: WorkflowSession = {
  patientId: userId,
  oid: userId,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  workflowId: 'workflowId',
  createdAt: twoDaysAgo,
  status: 'complete',
  promptPath: undefined,
}

export const getPatient2 = (data?: Partial<Patient>): PatientModel => {
  return {
    ...patient1,
    insuranceData: {
      insuranceQueueData: queueItem1,
    },
    ...data,
  }
}

export const manualInsuranceData1: ManualInsuranceData = {
  first_name: '',
  last_name: '',
  isActive: true,
  gender: 'female',
  patient_relationship_to_subscriber_code: '',
  insurance_card: {
    group_number: '',
    member_id: '',
    payer_id: '',
    plan_name: '',
    rx_bin: '',
    rx_pcn: '',
    payer_name: ',',
  },
}

export const eligibleInsuranceData1: EligibleInsuranceData = {
  insurance: {
    id: '',
    name: '',
  },
  plan: {
    plan_name: 'Humana',
    coverage_status_label: '',
    coverage_status: '',
    plan_type: 'MC',
  },
}

export const eligibleInsuranceData2: EligibleInsuranceData = {
  insurance: {
    id: '',
    name: '',
  },
  plan: {
    plan_name: 'Simple plan',
    coverage_status_label: '',
    coverage_status: '',
    plan_type: 'MC',
  },
}

export const incompletePatientProvidedData: Readonly<InsuranceData> = {
  hasInsurance: true,
  firstName: '',
  lastName: '',
  memberId: '',
  provider: '',
  planType: null,
}

export const completePatientProvidedData: Readonly<InsuranceData> = {
  firstName: 'John',
  lastName: 'Doe',
  memberId: '123435',
  provider: 'Humana',
  eligiblePayerId: 'ABC123',
  planType: null,
}

export const completePatientProvidedData2: Readonly<InsuranceData> = {
  firstName: 'John',
  lastName: 'Doe',
  memberId: '33221',
  provider: 'Random Provider',
  eligiblePayerId: 'ZYX987',
  planType: null,
}

export const problemsAddressedChronic: DecoratedProblem = {
  code: 'x',
  full_description: 'xyz',
  progression: 'Exacerbating',
  chronicity: 'Chronic',
  status: 'addressed',
  createdAt: '',
  oid: '',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
}

export const problemsAddressedAcute: DecoratedProblem = {
  code: 'x',
  full_description: 'xyz',
  progression: 'Stable',
  chronicity: 'Acute',
  status: 'addressed',
  createdAt: '',
  oid: '',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
}

export const problemsAddressedSmoking: DecoratedProblem = {
  code: 'T65.213A',
  full_description: 'xyz',
  progression: 'Exacerbating',
  chronicity: 'Chronic',
  status: 'addressed',
  createdAt: '',
  oid: '',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
}

export const problemsAddressedPsych: ProblemListProblem = {
  code: 'F06.30',
  full_description: 'xyz',
  progression: 'Exacerbating',
  chronicity: 'Chronic',
  status: 'addressed',
}

export const baseActionItem: ActionItemModel = {
  patientId: userId,
  createdAt: '2022-05-15T00:00:00.123Z',
  type: ActionItemType.LockNote,
  status: ActionItemStatus.Open,
  dueAt: '2022-05-16T00:00:00.123Z',
  assignmentMethod: ActionItemAssignmentMethod.Indirect,
  assignee: employeeId,
  optional: false,
  deleted: false,
  visitId: appointmentId,
  updatedAt: '2022-05-15T00:00:00.123Z',
}

export const promptOne: Prompt = {
  oid: 'j83wsdv21',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  contextKey: 'opioid_use_description',
  createdAt: '2022-04-11T20:44:40.345Z',
  updatedAt: '2022-04-11T20:44:40.345Z',
  content: [
    {
      contentType: 'header',
      primaryText: 'How would you describe your opioid use?',
      secondaryText:
        'Opioids include oxycodone (OxyContin), hydrocodone (Vicodin), morphine, methadone, fentanyl, and heroin.',
    },
    {
      contentType: 'response',
      responseType: 'single_selection',
      options: [
        {
          contextKey: 'opioid_use_description_not_under_control',
          primaryText: "I don't have my opioid use under control",
        },
        {
          contextKey: 'opioid_use_description_under_control',
          primaryText: 'I do have my opioid use under control',
        },
        {
          contextKey: 'opioid_use_description_never_used',
          primaryText: "I've never used opioids",
        },
      ],
    },
  ],
}

export const promptTwo: Prompt = {
  oid: 'd8d1v1ss',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  contextKey: 'opioid_use_history',
  createdAt: '2022-04-11T20:44:40.345Z',
  updatedAt: '2022-04-11T20:44:40.345Z',
  content: [
    {
      contentType: 'header',
      primaryText: 'How long have you been using opioids?',
      secondaryText: "About 3 million Americans struggle with opioid use. You're not alone.",
    },
    {
      contentType: 'response',
      responseType: 'single_selection',
      options: [
        {
          contextKey: 'opioid_use_history_less_than_six_months',
          primaryText: 'Less than 6 months',
        },
        {
          contextKey: 'opioid_use_history_six_to_eleven_months',
          primaryText: '6 to 11 months',
        },
        {
          contextKey: 'opioid_use_history_one_to_five_years',
          primaryText: '1 to 5 years',
        },
        {
          contextKey: 'opioid_use_history_six_to_ten_years',
          primaryText: '6 to 10 years',
        },
        {
          contextKey: 'opioid_use_history_more_than_ten_years',
          primaryText: 'More than 10 years',
        },
      ],
    },
  ],
}

export const promptThree: Prompt = {
  oid: 'n88ddf92',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  contextKey: 'insurance_provider',
  createdAt: '2022-04-11T20:44:40.345Z',
  updatedAt: '2022-04-11T20:44:40.345Z',
  content: [
    {
      contentType: 'header',
      primaryText: 'Same care for everyone',
      secondaryText:
        'We offer evidence-based treatment to all of our patients, regardless of how you choose to pay.',
    },
    {
      contentType: 'response',
      responseType: 'widget',
      widgetType: 'payment_method',
    },
  ],
}

export const promptFour: Prompt = {
  oid: '93nvx811',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  contextKey: 'only_treat_opioid_use',
  createdAt: '2022-04-11T20:44:40.345Z',
  updatedAt: '2022-04-11T20:44:40.345Z',
  content: [
    {
      contentType: 'header',
      primaryText: 'Currently, we only treat opioid use disorder',
      secondaryText: 'Here are some other resources...',
    },
  ],
}

export const baseWorkflow: Workflow & WorkflowPromptV2 = {
  oid: '12345abcdef',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  name: 'Onboarding Workflow v1',
  type: 'onboarding',
  // Haven's staging employee ID
  createdBy: '8xpinI8XPjPdiTDlKX99r0n6D3D3',
  prompts: [
    {
      promptId: promptOne.oid,
      branches: [
        {
          nextPromptId: promptFour.oid,
          responseContextKeys: ['opioid_use_description_never_used'],
        },
        { nextPromptId: promptTwo.oid },
      ],
    },
    { promptId: promptTwo.oid, branches: [{ nextPromptId: promptThree.oid }] },
    { promptId: promptThree.oid, branches: [] },
  ],
  createdAt: '2022-04-11T20:44:40.345Z',
  version: 2,
}

export const workflowSession: WorkflowSession = {
  oid: '48vns291',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  workflowId: '12345abcdef',
  patientId: userId,
  status: 'not_started',
  createdAt: '2022-04-11T20:44:40.345Z',
  promptPath: [promptOne.oid],
}

export const workflowResponse: PromptResponse = {
  oid: `${workflowSession.oid}_${promptOne.oid}`,
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  promptId: promptOne.oid,
  promptContextKey: promptOne.contextKey,
  sessionId: workflowSession.oid,
  workflowId: baseWorkflow.oid,
  createdAt: '2022-04-11T20:44:40.345Z',
  updatedAt: '2022-04-11T20:44:40.345Z',
  payload: { value: { contextKey: 'opioid_use_description_not_under_control' } },
}
export const historicalPrescription: DoseSpotPrescription = {
  PrescriptionId: 55620546,
  WrittenDate: '2012-06-14T13:19:19.097',
  Directions: 'OK',
  Quantity: '10',
  DispenseUnitId: 4,
  DispenseUnitDescription: 'Capsule(s)',
  Refills: '0',
  DaysSupply: 10,
  PharmacyId: 2909,
  PharmacyNotes: null,
  NoSubstitutions: false,
  EffectiveDate: '2012-06-14T00:00:00',
  LastFillDate: null,
  PrescriberId: 249546,
  PrescriberAgentId: null,
  RxReferenceNumber: null,
  Status: 1,
  Formulary: false,
  EligibilityId: 0,
  NonDoseSpotPrescriptionId: null,
  ErrorIgnored: false,
  SupplyId: null,
  CompoundId: null,
  FreeTextType: null,
  ClinicId: 25097,
  SupervisorId: null,
  IsUrgent: false,
  IsRxRenewal: false,
  RxRenewalNote: null,
  PatientMedicationId: 56152432,
  MedicationStatus: 1,
  Comment: null,
  DateInactive: null,
  Encounter: null,
  DoseForm: 'capsule',
  Route: 'oral',
  Strength: '200 mg',
  GenericProductName: 'ibuprofen 200 mg oral capsule',
  GenericDrugName: 'ibuprofen',
  LexiGenProductId: 6680,
  LexiDrugSynId: 18915,
  LexiSynonymTypeId: 59,
  LexiGenDrugId: 'd00015',
  RxCUI: '310964',
  OTC: true,
  NDC: '00113029858',
  Schedule: '0',
  DisplayName: 'Ibuprofen 200 mg capsule',
  MonographPath: 'HTMLICONS/d00015a1.htm',
  DrugClassification: 'central nervous system agents',
  StateSchedules: null,
  Brand: false,
}

export const currentPrescription: DoseSpotPrescription = {
  PrescriptionId: 55620546,
  WrittenDate: '2052-06-14T13:19:19.097',
  Directions: 'OK',
  Quantity: '10',
  DispenseUnitId: 4,
  DispenseUnitDescription: 'Capsule(s)',
  Refills: '0',
  DaysSupply: 10,
  PharmacyId: 2909,
  PharmacyNotes: null,
  NoSubstitutions: false,
  EffectiveDate: '2052-06-14T00:00:00',
  LastFillDate: null,
  PrescriberId: 249546,
  PrescriberAgentId: null,
  RxReferenceNumber: null,
  Status: 1,
  Formulary: false,
  EligibilityId: 0,
  NonDoseSpotPrescriptionId: null,
  ErrorIgnored: false,
  SupplyId: null,
  CompoundId: null,
  FreeTextType: null,
  ClinicId: 25097,
  SupervisorId: null,
  IsUrgent: false,
  IsRxRenewal: false,
  RxRenewalNote: null,
  PatientMedicationId: 56152432,
  MedicationStatus: 1,
  Comment: null,
  DateInactive: null,
  Encounter: null,
  DoseForm: 'capsule',
  Route: 'oral',
  Strength: '200 mg',
  GenericProductName: 'ibuprofen 200 mg oral capsule',
  GenericDrugName: 'ibuprofen',
  LexiGenProductId: 6680,
  LexiDrugSynId: 18915,
  LexiSynonymTypeId: 59,
  LexiGenDrugId: 'd00015',
  RxCUI: '310964',
  OTC: true,
  NDC: '00113029858',
  Schedule: '0',
  DisplayName: 'Ibuprofen 200 mg capsule',
  MonographPath: 'HTMLICONS/d00015a1.htm',
  DrugClassification: 'central nervous system agents',
  StateSchedules: null,
  Brand: false,
}

export const doseSpotPharmacy: DoseSpotPharmacy = {
  IsDefault: true,
  PharmacyId: 2,
  StoreName: 'The High on Potatoes Pharmacy',
  Address1: '420 High Street',
  Address2: 'string',
  City: 'Pottsville',
  State: 'HI',
  ZipCode: '42042',
  PrimaryPhone: '1-800-POTATO-HI',
  PrimaryPhoneType: 0,
  PrimaryFax: 'string',
  PhoneAdditional1: 'string',
  PhoneAdditionalType1: 0,
  PhoneAdditional2: 'string',
  PhoneAdditionalType2: 0,
  PhoneAdditional3: 'string',
  PhoneAdditionalType3: 0,
  PharmacySpecialties: ['suboxone'],
  ServiceLevel: 0,
  Latitude: 1,
  Longitude: 2,
}

export const doseSpotPharmacyResponse: DoseSpotPharmaciesResponse = {
  Items: [doseSpotPharmacy],
  Result: {
    ResultCode: 'OK',
    ResultDescription: 'Yuuuuurt',
  },
}

export const doseSpotPharmacySuccessResponse: DoseSpotResultResponse = {
  Result: {
    ResultCode: 'OK',
    ResultDescription: 'Yuuuuurt',
  },
}

const testPrescriptionFavorite: Readonly<PrescriptionFavoriteModel> = {
  favoriteName: 'Ibuprofen 200mg',
  medicationName: 'Ibuprofen',
  doseForm: 'capsule',
  strength: '200 mg',
  refills: 3,
  quantity: 1,
  daysSupply: 30,
  directions: 'Use as needed',
  substitutionAllowed: false,
  type: 'clinician',
  pharmacyNotes: '',
  employeeId,
}

export const getTestPrescriptionFavorite = (data?: Partial<PrescriptionFavorite>) => {
  return {
    ...testPrescriptionFavorite,
    ...data,
  }
}

export const cocmRegistryPatientId = '123456789xx'

const testCocmRegistryItem: Readonly<COCMRegistryItemModel> = {
  patientId: cocmRegistryPatientId,
  employeeId: 'xxxx33221',
  deleted: false,
  dateAdded: '2022-11-08T14:36:22.335Z',
  needsReview: true,
}

export const getTestCocmRegistryItem = (data?: Partial<COCMRegistryItem>) => {
  return {
    ...testCocmRegistryItem,
    ...data,
  }
}

const testObjectiveMeasure: Readonly<ObjectiveMeasureResponse> = {
  oid: 'FAKE_OID',
  timestamps: {
    createdAt: 0,
    updatedAt: 0,
  },
  compositeScore: 10,
  createdAt: new Date().toISOString(),
  completedAt: new Date().toISOString(),
  data: [],
  workflowSessionId: '123456789',
}

export const getTestObjectiveMeasure = (data?: Partial<ObjectiveMeasureResponse>) =>
  ({
    ...testObjectiveMeasure,
    ...data,
  }) as ObjectiveMeasureResponse

const testPhq8FormResponse: Phq8FormResponse = {
  ...testObjectiveMeasure,
  isFromPhq9: false,
  data: [
    {
      score: 1,
      key: 'little_interest_several',
    },
    {
      score: 1,
      key: 'feeling_down_several',
    },
    {
      score: 1,
      key: 'trouble_falling_asleep_several',
    },
    {
      score: 1,
      key: 'feeling_tired_several',
    },
    {
      score: 1,
      key: 'poor_appetite_several',
    },
    {
      score: 1,
      key: 'feeling_bad_about_yourself_several',
    },
    {
      score: 1,
      key: 'trouble_concentrating_several',
    },
    {
      score: 1,
      key: 'moving_or_speaking_slowly_several',
    },
  ],
}

export const getTestPhq8FormResponse = (data?: Partial<Phq8FormResponse>) => ({
  ...testPhq8FormResponse,
  ...data,
})
