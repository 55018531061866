import { ArrowLeftIcon, Space, Stack, TertiaryButton, Text, TitleTwo } from '@shared/components'
import { name } from '@shared/utils'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { useAuth } from '../../../common/hooks'
import { TpoConsentV1 } from '../../../documents/TpoConsentV1'
import { SignedAgreementInfo } from './SignedAgreementInfo'

export const TPOPage = () => {
  const navigate = useNavigate()
  const [consentQueryKey, consentsQueryFunction] = patientApi.getQuery(
    'GET /consents/:type/:version',
    {
      params: {
        type: 'tpo',
        version: '1',
      },
    },
  )
  const { currentUser } = useAuth()

  const consentQuery = useQuery(consentQueryKey, consentsQueryFunction)

  if (consentQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack>
      <TitleTwo>TPO consent</TitleTwo>
      <Stack>
        <Text>
          Patient Name:{' '}
          {name({
            first: currentUser?.data?.personalData?.firstName,
            last: currentUser?.data?.personalData?.lastName,
          }).full()}
        </Text>
        <Text>DOB: {currentUser?.data?.personalData.birthday}</Text>
        <TpoConsentV1 />
      </Stack>
      {consentQuery.data && (
        <>
          <Space h='sm' />
          <SignedAgreementInfo
            signature={consentQuery.data?.signature}
            date={consentQuery.data?.date}
            checkboxLabel='I have read, understand, and agree to this consent for treatment.'
          />
        </>
      )}
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => {
          navigate(-1)
        }}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
