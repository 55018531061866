import { sortBy } from './sort'
export const KEYSTONE_FIRST_PAYER_ID = '23284'
export const KEYSTONE_ELIGIBLE_PAYER_IDS = [KEYSTONE_FIRST_PAYER_ID, '77741', '42344']
export const AMERIHEALTH_ELIGIBLE_PAYER_IDS = ['22248', '77062'] as const
export const GEISINGER_PAYER_ID = '75273'
export const EMPIRE_BLUE_PAYER_ID = 'NYBLS'
export const HIGHMARK_PAYER_IDS = ['35145', 'PABLS']
export const FIDELIS_ELIGIBLE_PAYER_ID = '11315'
export const PA_MEDICAID_ELIGIBLE_PAYER_ID = 'PAMCD'
export const PA_UNITED_HEALTHCARE_PAYER_ID = '87726'
export const HIGHMARK_WHOLECARE_MEDICARE_PAYER_ID = '60550'
export const HIGHMARK_WHOLECARE_MEDICAID_PAYER_ID = '25169'
export const HEALTH_PARTNERS_PA_PAYER_ID = '80142'

export type PayerSearchOption =
  | 'member_dob'
  | 'member_id'
  | 'member_first_name'
  | 'member_last_name'
  | 'member_gender'

type PayerOnShortList = {
  value: string | typeof INSURANCE_OTHER_OPTION_ID
  label: string
  states?: string[]
  minRequiredInfo: PayerSearchOption[]
  // Do not add field unless wanting to skip payment info
  hasMedicaidPlanType?: boolean
}

/**
 * The list of Medicaid payers Ophelia currently accepts.
 */
export const MedicaidInsuranceList: PayerOnShortList[] = [
  {
    value: 'AZMCD',
    label: 'Medicaid of Arizona',
    states: ['Arizona'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: 'MEMCD',
    label: 'Medicaid of Maine',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MTMCD',
    label: 'Medicaid of Montana',
    states: ['Montana'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: 'NYMCD',
    label: 'Medicaid of New York',
    states: ['New York'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: PA_MEDICAID_ELIGIBLE_PAYER_ID,
    label: 'Medicaid of Pennsylvania',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'VAMCD',
    label: 'Medicaid of Virginia',
    states: ['Virginia'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: 'VTMCD',
    label: 'Medicaid of Vermont',
    states: ['Vermont'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'WAMCD',
    label: 'Medicaid of Washington',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '00423',
    label: 'Anthem Healthkeepers',
    states: ['Virginia'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
]

/**
 * The list of payers Ophelia currently accepts, used as a
 * selectable list in the Welcome Flow. Allows for an optional
 * `states` key for valid states - if missing, it is available
 * in all states.
 *
 * Some of these payer IDs appear more than once in the list.
 * Some payers have multiple plans, and we want to give patients and EMR users all options.
 *
 * The minRequiredInfo array includes the minimum search
 * criteria required for Eligible API to return any coverage info
 * for that payer. If the array is empty, coverage/eligibility
 * requests are not supported through Eligible API.
 */
export const PayersShortList: PayerOnShortList[] = [
  {
    value: '25169',
    label: 'Highmark Wholecare',
    states: ['Pennsylvania'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '22248',
    label: 'AmeriHealth Caritas PA',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'CTBLS',
    label: 'Anthem BCBS of Connecticut',
    states: ['Connecticut'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MEBLS',
    label: 'Anthem BCBS of Maine',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'VABLS',
    label: 'Anthem BCBS of Virginia',
    states: ['Virginia'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MDBLS',
    label: 'BCBS Carefirst Maryland',
    states: ['Maryland'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MTBLS',
    label: 'Blue Cross Blue Shield of Montana',
    states: ['Montana'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: 'VTBLS',
    label: 'Blue Cross Blue Shield of Vermont',
    states: ['Vermont'],
    minRequiredInfo: [
      'member_id',
      'member_first_name',
      'member_last_name',
      'member_dob',
      'member_gender',
    ],
  },
  {
    value: 'WABLC',
    label: 'Blue Cross of Washington (Premera)',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '23045',
    label: 'Capital BCBS',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'SX065',
    label: 'CDPHP',
    states: ['New York'],
    minRequiredInfo: ['member_id'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'NYBLS',
    label: 'Anthem BCBS (Formerly Empire BCBS)',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '11315',
    label: 'Fidelis Care of New York',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: '75273',
    label: 'Geisinger Health Plan',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: '80141',
    label: 'Healthfirst',
    states: ['New York'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '35145',
    label: 'Highmark BCBS',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  // Humana does not support coverage/eligibility requests via Eligible.com
  { value: '61101', label: 'Humana', minRequiredInfo: [] },
  {
    value: 'JHHP',
    label: 'Johns Hopkins Healthcare, LLC',
    states: ['Maryland'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: '23284',
    label: 'Keystone First',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'MEMCD',
    label: 'MaineCare',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: '14165',
    label: 'MVP Health Care',
    states: ['New York', 'Vermont'],
    minRequiredInfo: ['member_id', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'TREST',
    label: 'Tricare East',
    states: [
      'New York',
      'Connecticut',
      'Maine',
      'Maryland',
      'Texas',
      'Virginia',
      'Vermont',
      'Florida',
      'New Jersey',
      'Pennsylvania',
    ],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: '87726',
    label: 'United HealthCare',
    states: ['Pennsylvania', 'Arizona', 'Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  // United Mines Worker Association does not support coverage/eligibility requests via Eligible.com
  { value: '52180', label: 'UMWA Health & Retirement Funds', minRequiredInfo: [] },
  {
    value: 'VABLS',
    label: 'Blue Card PPO',
    states: [
      'Pennsylvania',
      'New York',
      'Connecticut',
      'Maine',
      'Montana',
      'Maryland',
      'Washington',
      // TODO(haven): When Anthem BCBS Virginia ('VABLS' payer) is added back in-network, add back 'Virginia' to state list
    ],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'AZMCR',
    label: 'Arizona Medicare',
    states: ['Arizona'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'CSMCR',
    label: 'California Medicare',
    states: ['California'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'CTMCR',
    label: 'Connecticut Medicare',
    states: ['Connecticut'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'FLMCR',
    label: 'Florida Medicare',
    states: ['Florida'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: '00901',
    label: 'Maryland Medicare',
    states: ['Maryland'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'MEMCR',
    label: 'Maine Medicare',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'MTMCR',
    label: 'Montana Medicare',
    states: ['Montana'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'NJMCR',
    label: 'New Jersey Medicare',
    states: ['New Jersey'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'NYMCR',
    label: 'New York Medicare',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'PAMCR',
    label: 'Pennsylvania Medicare',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'TXMCR',
    label: 'Texas Medicare',
    states: ['Texas'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'VAMCR',
    label: 'Virginia Medicare',
    states: ['Virginia'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'VTMCR',
    label: 'Vermont Medicare',
    states: ['Vermont'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'WAMCR',
    label: 'Washington Medicare',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: '39026',
    label: 'UMR',
    states: ['Arizona', 'Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '75190',
    label: 'BCBS Carefirst Administration',
    states: ['Maryland'],
    // Does not support coverage/eligibility requests via Eligible.com
    minRequiredInfo: [],
  },
  {
    value: 'SB580',
    label: 'BCBS Carefirst NCA',
    states: ['Maryland'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'NYBLS',
    label: 'Empire Blue (Empire Healthplus Medicaid)',
    states: ['New York'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '77062',
    label: 'AmeriHealth CHC/VIP',
    states: ['Pennsylvania'],
    // Does not support coverage/eligibility requests via Eligible.com
    minRequiredInfo: [],
  },
  {
    value: '15460',
    label: 'Highmark Senior Health Company',
    states: ['Pennsylvania'],
    // Does not support coverage/eligibility requests via Eligible.com
    minRequiredInfo: [],
  },
  {
    value: '84223',
    label: 'Keystone First VIP',
    states: ['Pennsylvania'],
    // Does not support coverage/eligibility requests via Eligible.com
    minRequiredInfo: [],
  },
  {
    value: '52123',
    label: 'Johns Hopkins - US Family Health Plan (USFHP)',
    // Note: This plan is also available in DC and West Virginia, but we do not operate in those states.
    states: ['Maryland', 'Delaware', 'Virginia'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  // Note: This has the same payer ID as Keystone First, but is a different plan.
  {
    label: 'Keystone First Community Healthchoices',
    value: '23284',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    label: 'Independence Blue Cross in PA',
    value: '54704',
    states: ['Pennsylvania', 'New Jersey'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    label: 'Independence Administrators',
    value: 'TA720',
    states: ['Pennsylvania', 'New Jersey'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    label: 'Keystone East',
    value: '95056',
    states: ['Pennsylvania', 'New Jersey'],
    hasMedicaidPlanType: true,
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  ...MedicaidInsuranceList,
]

export const INSURANCE_OTHER_OPTION_ID = 'other_none_of_these' as const
export const INSURANCE_OTHER_OPTION: PayerOnShortList = {
  label: 'Other/none of these',
  value: INSURANCE_OTHER_OPTION_ID,
  minRequiredInfo: [],
}

type InsurancePlanId = '' | `${string}__${string}` | 'other_none_of_these'

/**
 * Transforms the eligiblePayerId and provider values into a insurance plan unique identifier.
 * This is used when selecting an insurance plan on a select form.
 */
export const getInsurancePlanId = (
  eligiblePayerId: string | undefined,
  provider: string | undefined,
): InsurancePlanId => {
  if (eligiblePayerId === INSURANCE_OTHER_OPTION_ID) {
    return INSURANCE_OTHER_OPTION_ID
  } else if (eligiblePayerId && provider) {
    return `${eligiblePayerId}__${provider}`
  }

  return ''
}

/**
 * Separates an insurance plan unique identifier into two fields:
 * eligiblePayerId & provider
 */
export const getPayerIdAndProvider = (
  value: InsurancePlanId,
): {
  eligiblePayerId: string
  provider: string
} => {
  if (!value) {
    return {
      eligiblePayerId: '',
      provider: '',
    }
  }

  if (value === INSURANCE_OTHER_OPTION.value) {
    return {
      eligiblePayerId: INSURANCE_OTHER_OPTION.value,
      provider: INSURANCE_OTHER_OPTION.label,
    }
  }
  const insurance = value.split('__') as [string, string]

  return {
    eligiblePayerId: insurance[0],
    provider: insurance[1],
  }
}

/**
 * Returns the appropriate list to display for selecting an insurance plan.
 */
export const getPayersShortList = (state?: string, showOtherOption = true, showStates = false) => {
  return (
    PayersShortList
      // Remove plans from other states
      .filter(payer => !payer.states || !state || payer.states.includes(state))
      // Sort alphabetically
      .sort(sortBy({ key: 'label', order: 'ASC' }))
      // add the OTHER_OPTION as the last one
      .concat(showOtherOption ? [INSURANCE_OTHER_OPTION] : [])
      // Create unique values
      .map(payer => {
        if (showStates) {
          return {
            value: `${payer.value}__${payer.label}` as const,
            label: payer.label,
            states: payer.states,
          }
        }
        return {
          value: `${payer.value}__${payer.label}` as const,
          label: payer.label,
        }
      })
  )
}

export const isPayerInNetwork = ({ payerId, state }: { payerId: string; state?: string }) => {
  const inNetworkInsurances = getPayersShortList(state, false)
  return inNetworkInsurances.some(
    inn => getPayerIdAndProvider(inn.value).eligiblePayerId === payerId,
  )
}

/**
 * Returns minimum search criteria required to request coverage info
 * from Eligible API for a given payer
 */
export const getPayerSearchOptions = (value: InsurancePlanId) => {
  const { eligiblePayerId } = getPayerIdAndProvider(value)
  const payerFromShortList = PayersShortList.find(payer => payer.value === eligiblePayerId)
  return payerFromShortList?.minRequiredInfo || []
}

export type PlanType = '12' | '13' | '14' | 'CH' | 'CI' | 'FI' | 'HM' | 'MB' | 'MC' | 'VA' | 'WC'

export const STRAIGHT_BUNDLE_PLANS = {
  'Empire Blue': {
    eligiblePayerIds: [EMPIRE_BLUE_PAYER_ID],
    nonQualifyingPlanTypes: ['MC', 'MB'],
  },
  Highmark: {
    eligiblePayerIds: HIGHMARK_PAYER_IDS,
    nonQualifyingPlanTypes: ['MC', 'MB'],
  },
  Fidelis: {
    eligiblePayerIds: [FIDELIS_ELIGIBLE_PAYER_ID],
    nonQualifyingPlanTypes: [],
  },
  HighmarkWholecare: {
    eligiblePayerIds: [HIGHMARK_WHOLECARE_MEDICARE_PAYER_ID, HIGHMARK_WHOLECARE_MEDICAID_PAYER_ID],
    nonQualifyingPlanTypes: [],
  },
} satisfies {
  [key: string]: {
    eligiblePayerIds: string[]
    nonQualifyingPlanTypes: PlanType[]
  }
}
/*
 * In the above, satisfies ensures that each entry in STRAIGHT_BUNDLE_PLANS conforms to the provided type, but also allows us to treat the object as a const.
 * This is useful in the below function which uses this object as a lookup table.
 */

export function isBundlePlanOfType({
  type,
  eligiblePayerId,
  planType,
}: {
  type: keyof typeof STRAIGHT_BUNDLE_PLANS
  eligiblePayerId: string
  planType: PlanType | null
}) {
  const bundlePlan = STRAIGHT_BUNDLE_PLANS[type]
  const eligiblePayerIdMatches = bundlePlan.eligiblePayerIds.includes(eligiblePayerId)
  const planTypeMatches = !bundlePlan.nonQualifyingPlanTypes.some(type => type === planType)
  return eligiblePayerIdMatches && planTypeMatches
}

export function isBundlePlan({
  eligiblePayerId,
  planType,
}: {
  eligiblePayerId: string
  planType: PlanType | null
}) {
  const bundlePlan = Object.values(STRAIGHT_BUNDLE_PLANS).find(bundle =>
    bundle.eligiblePayerIds.includes(eligiblePayerId),
  )
  return bundlePlan && !bundlePlan.nonQualifyingPlanTypes?.some(type => type === planType)
}
