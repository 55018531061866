import { useForm } from '@mantine/form'
import {
  Box,
  DailyCalendar,
  DailyCalendarRadioGroup,
  Loader,
  PrimaryButton,
  Radio,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { YYYYMMDD, getOpheliaHttpError } from '@shared/types'
import { dayjs, template, toTime } from '@shared/utils'
import React, { useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { ContactSupport } from '../../common/components'
import { CalendarMessage } from '../../common/components/CalendarMessage'
import * as FullStory from '../../common/fullstory'
import { useMyMutation, useMyQuery, usePortalDims } from '../../common/hooks'
import { getFullPath } from '../../common/routes'

export const GoToThePortalButton = () => {
  const { isMobile } = usePortalDims()

  return (
    <PrimaryButton
      fullWidth={isMobile}
      to={getFullPath('welcome.children.signin')}
      component={Link}
    >
      Go to the portal
    </PrimaryButton>
  )
}

export const NothingAvailable = () => {
  useLifecycle({
    onMount: () => {
      FullStory.event('No Availability For Follow-Up Visit Hold')
    },
  })

  return (
    <Stack>
      <TitleTwo>Unfortunately, nothing&apos;s available right now</TitleTwo>
      <Text>
        Our care coordination team will reach out shortly to help find a time that works best for
        you.
      </Text>
      <GoToThePortalButton />
      <ContactSupport text='Need help? Text us at' />
    </Stack>
  )
}

/**
 * This component orchestrates which subcomponent to render based on the state of the calendar hold.
 */
export const ScheduleCalendarHold = () => {
  const params = useParams<{ calendarHoldId: string }>()

  const {
    isLoading,
    isError,
    data: calendarHold,
  } = useMyQuery('GET /calendar-holds/:id', {
    params: {
      id: params.calendarHoldId || '',
    },
  })

  if (isLoading) {
    return (
      <Stack h='50vh' align='center' justify='center'>
        <Loader />
      </Stack>
    )
  }

  if (isError || !calendarHold?.data) {
    return (
      <Stack align='center'>
        <Text>Something went wrong. Try again later.</Text>
        <GoToThePortalButton />
        <ContactSupport text='Need help? Text us at' />
      </Stack>
    )
  }

  if (calendarHold?.data.appointmentDetails) {
    return <AlreadyScheduled {...calendarHold.data.appointmentDetails} />
  }

  return <Calendar calendarHoldId={params.calendarHoldId || ''} days={calendarHold.data.days} />
}

/**
 * This is the view that patients see when they have successfully scheduled a follow-up visit.
 */
export const JustScheduled = (props: {
  clinicianName: string
  timezone: string
  datetime: string
}) => {
  const { showBanner } = useBanner()
  const [_, setSearchParams] = useSearchParams()

  useLifecycle({
    onMount: () => {
      /**
       * We add the search param when a patient successfully schedules a follow-up visit so
       * that we can display a customer.io survey to the patient.
       */
      setSearchParams({
        self_scheduled: 'true',
      })
      showBanner({
        type: 'success',
        label: 'Follow-up visit scheduled',
      })
    },
  })

  const day = dayjs(props.datetime).tz(props.timezone)

  const title = template(
    'Your follow-up visit for {date} at {time} {timezone} with {clinicianName} is scheduled',
    {
      date: day.format('MMMM D'),
      time: day.format('h:mma'),
      timezone: day.format('z'),
      clinicianName: props.clinicianName,
    },
  )

  return (
    <Stack>
      <TitleTwo>{title}</TitleTwo>
      <Text>
        {`If you don't attend, or if you cancel less than 24 hours before your visit, you may be charged $20.`}
      </Text>
      <GoToThePortalButton />
      <ContactSupport text='Need help? Text us at' />
    </Stack>
  )
}

/**
 * This is the view that patients see when they already have a follow-up visit scheduled and revisit the page.
 */
export const AlreadyScheduled = (props: {
  clinicianName: string
  timezone: string
  datetime: string
}) => {
  const day = dayjs(props.datetime).tz(props.timezone)
  const title = template(
    'You already have a follow-up visit scheduled for {date} at {time} {timezone} with {clinicianName}',
    {
      date: day.format('MMMM D'),
      time: day.format('h:mma'),
      timezone: day.format('z'),
      clinicianName: props.clinicianName,
    },
  )

  return (
    <Stack>
      <TitleTwo>{title}</TitleTwo>
      <Text>{`We're looking forward to seeing you.`}</Text>
      <GoToThePortalButton />
      <ContactSupport text='Need help? Text us at' />
    </Stack>
  )
}

export const Calendar = ({
  days,
  calendarHoldId,
}: {
  days: YYYYMMDD[]
  calendarHoldId: string
}) => {
  const { showBanner, hideBanner } = useBanner()
  const [day, setDay] = useState<dayjs.Dayjs | null>(null)

  const scheduleMutation = useMyMutation('POST /calendar-holds/:id/appointments', {
    onError: error => {
      void availabilityQuery.refetch()
      const errorMessage = getOpheliaHttpError(error, 'An error occurred')
      showBanner({
        type: 'error',
        label: errorMessage,
      })
      FullStory.event('Error Scheduling Follow-Up Visit For Hold', {
        errorMessage,
      })
    },
    onSuccess: ({ data }) => {
      FullStory.event('Scheduled Follow-Up Visit For Hold', {
        ...data,
      })
    },
  })

  const scheduledAppointmentDetails = scheduleMutation.data?.data

  const availableDaysQuery = useMyQuery(
    'GET /calendar-holds/:id/available-days',
    {
      params: {
        id: calendarHoldId,
      },
    },
    {
      onSuccess: ({ data }) => {
        if (data.startDay) {
          setDay(dayjs(data.startDay))
        }

        // if no available days, we display the NothingAvailable component
        FullStory.event('Loaded Availability For Follow-Up Visit Hold', {
          numAvailableDays: availableDays.length,
        })
      },
      onError: error => {
        /**
         * In case of error, default to the days provided by the hold.
         * We still want to show the patient the calendar, and allow them to manually
         * scroll through the days to find an available one.
         */
        setDay(dayjs(days[0]))
        FullStory.event('Error Getting Availability For Follow-Up Visit Hold', {
          errorMessage: getOpheliaHttpError(error, 'n/a'),
          endpoint: 'available-days',
        })
      },
    },
  )

  /**
   * Before displaying any availability, first check which days of the hold
   * are available for scheduling for the given calendar ID / clinician.
   * If the availableDaysQuery does not resolve, default to the days provided by the hold.
   * This will still ensure that the patient does not schedule something on an unavailable day.
   * However, the patient may need to scroll through empty days to find an available one.
   */
  const availableDays = availableDaysQuery.data?.data.availableDays || days

  const availabilityQuery = useMyQuery(
    'GET /calendar-holds/:id/availability',
    {
      params: {
        id: calendarHoldId,
      },
      query: {
        day: day?.format('YYYY-MM-DD') || ('' as YYYYMMDD),
      },
    },
    {
      /**
       * The availability query should be disabled when the appointment details exist.
       * That means that the appointment has already been scheduled and we don't need to fetch the availability anymore.
       */
      enabled: Boolean(day) && !scheduledAppointmentDetails,
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      onError: error => {
        const errorMessage = getOpheliaHttpError(error, 'Something went wrong, try again later')
        showBanner({
          type: 'error',
          label: errorMessage,
        })
        FullStory.event('Error Getting Availability For Follow-Up Visit Hold', {
          errorMessage,
          endpoint: 'availability',
        })
      },
    },
  )

  const { isMobile } = usePortalDims()

  const isSaving = scheduleMutation.isLoading
  // Availability is dependent on the available days and the selected day
  const isAvailabilityLoading = availableDaysQuery.isLoading || availabilityQuery.isLoading
  const slots = availabilityQuery.data?.data.availability || []

  const form = useForm({
    initialValues: {
      datetime: '',
    },
    validate: {
      datetime: isAnySelected(slots?.map(slot => slot.datetime) ?? [], 'Required'),
    },
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    scheduleMutation.mutate({
      params: {
        id: calendarHoldId,
      },
      data: {
        datetime: dayjs(form.values.datetime).toISOString(),
      },
    })
  }

  // When the mutation returns appointment details, show the confirmation screen
  if (scheduledAppointmentDetails) {
    return <JustScheduled {...scheduledAppointmentDetails} />
  }

  // Show the NothingAvailable component if there are no available days instead of the calendar
  if (!availableDaysQuery.isLoading && !availableDaysQuery.isError && availableDays.length === 0) {
    return <NothingAvailable />
  }

  return (
    <Stack spacing='lg'>
      <TitleTwo>Select a time for your next follow-up visit</TitleTwo>
      {day ? (
        <DailyCalendar
          value={day.toISOString()}
          range={availableDays}
          disabled={isSaving}
          onChange={value => {
            form.clearErrors()
            form.setValues({ datetime: value })
            setDay(dayjs(value))
            hideBanner()
          }}
        >
          <DailyCalendarRadioGroup
            size={6}
            loading={isAvailabilityLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...form.getInputProps('datetime')}
          >
            {slots.map(slot => (
              <Radio
                key={slot.datetime}
                value={slot.datetime}
                label={dayjs(slot.datetime).format('h:mma z')}
                disabled={isSaving}
              />
            ))}
          </DailyCalendarRadioGroup>
        </DailyCalendar>
      ) : (
        <Loader
          style={{
            margin: 'auto',
          }}
        />
      )}
      <PrimaryButton
        test-id='button:schedule'
        fullWidth={isMobile}
        loading={isSaving}
        onClick={() => {
          onSubmit()
        }}
      >
        Schedule visit
      </PrimaryButton>
      <Box
        onClick={() => {
          FullStory.event('Clicked Support Because No Visit Hold Time Works', {
            numAvailableDays: availableDays.length,
          })
        }}
      >
        <ContactSupport text="Don't see a time that works? Text us at" />
      </Box>
    </Stack>
  )
}
