import {
  ArrowLeftIcon,
  Box,
  Breadcrumbs,
  ChevronRightIcon,
  LinkText,
  TertiaryButton,
  Text,
} from '@shared/components'
import React, { useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { usePortalDims } from '../../hooks'
import { routes } from '../../routes'
import { PortalBreadcrumbsContext } from './PortalContext'

const breadcrumbMap: [string, string][] = [
  [routes.portal.index, 'Dashboard'],
  [routes.portal.children.account, 'Account'],
  [routes.portal.children.prescriptions, 'Prescriptions'],
  [routes.portal.children.billing.index, 'Billing & invoices'],
  [routes.portal.children.documents.index, 'Documents'],
  [routes.portal.children.documents.children.financialAgreement, 'Financial agreement'],
  [routes.portal.children.documents.children.treatmentConsent, 'Consent for treatment'],
  [routes.portal.children.documents.children.releaseOfInformation, 'Release of information'],
  [routes.portal.children.documents.children.tpo, 'TPO consent'],
  [routes.portal.children.visits, 'Visits'],
  [routes.portal.children.reschedule.index, 'Visits'],
]

export const PortalBreadcrumbs = () => {
  const { breadcrumbs } = useContext(PortalBreadcrumbsContext)
  const location = useLocation()
  const { isMobile } = usePortalDims()

  const elements = useMemo(() => {
    let pathnameParts = location.pathname.split('/').filter(Boolean) as [string, ...string[]]

    if (isMobile && pathnameParts.length > 1) {
      let parentIndex = pathnameParts.length - 2
      let parentBreadcrumb: [string, string] | undefined

      do {
        const parentPath = pathnameParts[parentIndex--]
        parentBreadcrumb = breadcrumbMap.find(([key]) => key.endsWith(String(parentPath)))
      } while (!parentBreadcrumb)

      return <BackToParent text={`Back to ${parentBreadcrumb[1].toLocaleLowerCase()}`} />
    }

    if (breadcrumbs?.type === 'override') {
      pathnameParts = [pathnameParts[0], breadcrumbs.text]
    }

    return pathnameParts
      .map((pathnamePart, idx) => {
        const isLastBreadcrumb = pathnameParts.length - 1 === idx

        const formattedBreadcrumb: string | undefined =
          (isLastBreadcrumb && breadcrumbs?.type === 'override' && breadcrumbs.text) ||
          (isLastBreadcrumb && breadcrumbs?.type === 'tail' && breadcrumbs.text) ||
          breadcrumbMap.find(([key]) => key.endsWith(pathnamePart))?.[1]

        if (!formattedBreadcrumb) {
          return undefined
        }

        return isLastBreadcrumb ? (
          <Text key={pathnamePart}>{formattedBreadcrumb}</Text>
        ) : (
          <LinkText
            component={Link}
            to={`/${pathnameParts.slice(0, idx + 1).join('/')}`}
            key={pathnamePart}
          >
            {formattedBreadcrumb}
          </LinkText>
        )
      })
      .filter(Boolean)
  }, [location.pathname, isMobile, breadcrumbs])

  return (
    <Breadcrumbs
      style={{ alignItems: 'center' }}
      separator={
        <Box
          sx={theme => ({
            height: `calc(${theme.fontSizes.xs} * 2)`,
            color: theme.other.colors.background[1],
          })}
        >
          <ChevronRightIcon />
        </Box>
      }
    >
      {elements}
    </Breadcrumbs>
  )
}

const BackToParent = ({ text }: { text: string }) => (
  <TertiaryButton p={0} component={Link} to={routes.portal.index} leftIcon={<ArrowLeftIcon />}>
    {text}
  </TertiaryButton>
)
