import { z } from 'zod'
import { ISOStringSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

const PostTextSummarizationReqRes = {
  req: z.object({
    body: z.object({
      patientId: z.string(),
      startDate: ISOStringSchema,
      endDate: ISOStringSchema,
    }),
  }),
  res: z.object({
    data: z.object({
      summary: z.string(),
    }),
  }),
}

export const TextSummarizationRouteSchemas = {
  'POST /text-summarization': PostTextSummarizationReqRes,
} satisfies ApiSchemas
