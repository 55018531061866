import { z } from 'zod'
import { YYYYMMDDSchema } from './dates'

export const MESSAGE_QUEUE_API_KEY_HEADER = 'cloud-tasks-api-key' as const

export const MessageSchema = z.discriminatedUnion('name', [
  z.object({
    name: z.literal('drop-in-clinic-reminder'),
    scheduleTimeUnix: z.number().positive(),
    payload: z.object({
      patientId: z.string(),
      clinicId: z.string(),
    }),
  }),
  z.object({
    name: z.literal('fine-tune-calendar-holds-for-clinician'),
    payload: z.object({
      employeeId: z.string(),
      intendedDay: YYYYMMDDSchema,
      forecastedCapacityMinusHoldsForAllClinicians: z.array(
        z.object({
          employeeId: z.string(),
          numMinutesForecastedCapacityMinusHoldsOneWeekBeforeIntendedDay: z.number(),
          numMinutesForecastedCapacityMinusHoldsForIntendedDay: z.number(),
        }),
      ),
    }),
  }),
])
